<template>
  <div id="LoanIndex">
    <!-- 查询 -->
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.student" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.year +'年'}}</span></span>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <span @click="toStuDeatil(row)"> <b style="font-size:15px;color:black">{{row.stuName}}</b> {{  row.stuNo}}</span> <van-icon name="arrow" color="#23AB72" @click="toDetail(row)" />
            </div>
            <div class="custom-centent  hidden-text" style="width:95%;margin-top:7px">
              <svg-icon icon-class="unit" size="15" /> 班级: {{row.className}}
            </div>
            <div class="custom-centent  hidden-text" style="width:95%;margin-top:7px">
              <svg-icon icon-class="date" size="15" /> 等级: {{row.gradeName}}
            </div>
            <div class="custom-centent margin6 hidden-text">
              <van-icon name="refund-o" size="15" /> 金额: {{row.pbv}}
            </div>
          </template>
          <template #right-icon>
            <span v-if="statustag(row.spzt,'success')" class="audit success-color b-color-success">审核通过</span>
            <span v-else-if="statustag(row.spzt,'info')" class="audit info-color b-color-info">待审核</span>
            <span v-else-if="statustag(row.spzt,'error')" class="audit error-color b-color-error">审核不通过</span>
            <span v-else-if="statustag(row.spzt,'warning')" class="audit warning-color b-color-warning">审核驳回</span>
            <div class="ckeckbox-st" @click="checkHandel(row)">
              <van-checkbox v-model="row.checked" icon-size="21px" shape="square"></van-checkbox>
            </div>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span v-if="statustag(row.spzt,'success')" class="success-color hidden-text95">
              <div class="status-tag ok-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'info')" class="info-color hidden-text95">
              <div class="status-tag info-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'error')" class="error-color hidden-text95 ">
              <div class="status-tag error-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
            <span v-else-if="statustag(row.spzt,'warning')" class="warning-color hidden-text95 ">
              <div class="status-tag warning-tag"></div>{{getSpztLabel(row.spzt)}}
            </span>
          </template>
          <template #right-icon>
            <van-button class="table-del-btn" :disabled="stuDisableBtn(row.spzt,'del')" @click="toDel(row)" :loading="row.delLoading" plain round size="small" type="warning">删除</van-button>
            <van-button class="table-edit-btn" :disabled="stuDisableBtn(row.spzt,'edit')" @click="toEdit(row)" plain round size="small" type="primary">编辑</van-button>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <div class="batch-button">
      <van-row>
        <van-col span="5">
          <van-checkbox class="batch-checked" @click="selcetAll" v-model="checkedAll" checked-color="#23AB72">全选</van-checkbox>
        </van-col>
        <van-col span="9">
          <div class="batch-checked-text" v-html="selectionText"></div>
        </van-col>
        <van-col span="10">
          <van-button round block type="primary" color="#23AB72" :disabled="this.selections.length ===0" @click="batchAudit()">批量审批</van-button>
        </van-col>
      </van-row>
    </div>
    <van-dialog v-model="showAudit" title="审批" :showConfirmButton="false" width="90%">
      <div @click="toCloseAudit(false)" class="cancel">取消</div>
      <van-form ref="form">
        <van-field v-if="isGrade" v-model="audit.appendData.field1" :border="true" label="奖学金等级" placeholder="选择奖学金等级" :rules="[{ required: true, message: '选择认定等级' }]">
          <template #input>
            <select class="select" @change="clickSelect" v-model="filed">
              <option value="0">请选择奖学金等级</option>
              <option v-for="item in  dataDj" :key="item.id" :value="item.value">{{item.label}}</option>
            </select>
          </template>
        </van-field>
        <van-field v-model="audit.appendData.opinion" :border="true" rows="3" label="审核意见" type="textarea" maxlength="50" placeholder="请输入审核意见" show-word-limit :rules="[{ required: true, message: '请输入审核意见' }]" />
      </van-form>
      <van-row style="padding:10px 10px 20px 10px" justify="center">
        <van-col span="8" align="center">
          <van-button type="warning" :loading="auditLoading1" :disabled="auditDisabled1" round style="width:80%" @click="confirmAudit(8)">驳回</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="danger" :loading="auditLoading2" :disabled="auditDisabled2" round style="width:80%" @click="confirmAudit(9)">拒绝</van-button>
        </van-col>
        <van-col span="8" align="center">
          <van-button type="primary" :loading="auditLoading3" :disabled="auditDisabled3" round style="width:80%" @click="confirmAudit(1)">同意</van-button>
        </van-col>
      </van-row>
    </van-dialog>
    <switch-role @change="toQuery()"></switch-role>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import batchAudit from "@/mixins/batchAudit";
import crudGj from '@/api/modules/jz/gjzxj'
import { getAwardById } from '@/api/modules/jz/project'

export default {
  name: "LoanIndex",
  mixins: [initData, batchAudit],
  dicts: ["xj_shzt", "xj_nj"],
  data() {
    return {
      audit: {
        appendData: {
          opinion: "同意",
          like: [],
          field3: "",
          field1: "",
        },
        coreKey: "",
        type: "JZGL",
        callback: "api/jz/gjzxj/approval",
        mkCode: "JZ",
        coreCode: "GJZXJ",
        status: 9,
      },
      jzxm: {},
      dataDj: [],
      isGrade: false,
      filed: "0",
    };
  },
  created() {
    this.$nextTick(() => {
      // 处理数据
      getAwardById(7).then((data) => {
        if (data) {
          this.jzxm = data
          // 是否有等级
          this.isGrade = data.isGrade
          if (data.isGrade && data.jxjdjgls.length > 0) {
            let dataDj = data.jxjdjgls.map((item) => {
              return {
                label: item.gradeName,
                value: JSON.stringify({
                  value: item.gradeCode,
                  label: item.gradeName,
                  pbv: item.pbv,
                }),
              }
            })
            this.dataDj = dataDj
          } else if (data.isGrade && data.jxjdjgls.length === 0) {
            this.audit.appendData.field1 = JSON.stringify({
              value: '',
              label: '无等级',
              pbv: this.jzxm.pbv,
            })
          }
        }
      })
    })
  },
  methods: {
    resetField() {
      this.audit = {
        appendData: {
          opinion: "同意",
          like: [],
          field3: "",
          field1: "",
        },
        coreKey: "",
        type: "JZGL",
        callback: "api/jxj/nation/approval",
        mkCode: "JZ",
        coreCode: "NATION",
        status: 9,
      }
      this.filed = '0'
    },
    clickSelect() {
      if (this.filed === '0') {
        this.audit.appendData.field1 = null
      } else {
        this.audit.appendData.field1 = this.filed
      }
    },
    beforeInit() {
      this.url = "api/jz/gjzxj";
      this.sort = ['spzt,asc', 'year,desc', 'unitNo,desc'];
      // 设置审批字段
      this.spIdField = "spzt"
      this.beforeRefresh()
      return true;
    },
    beforeRefresh() {
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      if (roles.indexOf('自身') > -1) {
        this.query.spzt = '1,2,3,9,101,201,301,108,208,308'
      }
      if (roles.indexOf('本级') > -1) {
        this.query.spzt = '2,3,9,201,301,208,308'
      }
      if (roles.indexOf('全部') > -1) {
        let roleName = this.user.roles.map((item) => {
          return item.name
        })
        if (roleName.indexOf('超级管理员') === -1) {
          // 非超级管理员
          this.query.spzt = '3,9,301,308'
        }
      }
    },
    toDetail(data) {
      this.$router.push({
        path: "/xs/a05/20001/detail",
        query: { id: data.id },
      });
    },
    // 编辑
    toEdit(data) {
      this.$router.push({
        path: "/xs/a05/20001/form",
        query: { id: data.id },
      });
    },
    // 删除操作
    toDel(data) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "你确定要删除该条数据吗？",
        })
        .then(() => {
          this.$set(data, "delLoading", true);
          crudGj
            .del([data.id])
            .then((res) => {
              this.$notify({
                type: "success",
                message: "操作成功",
                duration: 2000,
              });
              this.init();
              this.$set(data, "delLoading", false);
            })
            .catch((e) => {
              this.$set(data, "delLoading", false);
            });
        })
        .catch(() => {
          this.$set(data, "delLoading", false);
        });
    },
    stuDisableBtn(spzt, type) {
      let status = parseInt(spzt);
      if ([9].indexOf(status) > -1) {
        return true;
      }
      if (type === "del") {
        if ([2, 3, 4, 5].indexOf(status) > -1) {
          return true;
        }
      } else if (type === "edit") {
        if ([101, 201, 301, 2, 3].indexOf(status) > -1) {
          return true;
        }
      }
      return false;
    },
    async confirmAudit(type) {
      let roles = this.user.roles.map((item) => {
        return item.dataScope
      })
      let value = []
      if (roles.indexOf('自身') > -1) {
        value = this.selections.filter((item) => {
          return ['1', '101'].indexOf(item.spzt + '') > -1
        })
        this.audit.appendData.field3 = 1
      }
      if (roles.indexOf('本级') > -1) {
        value = this.selections.filter((item) => {
          return ['2', '3', '201'].indexOf(item.spzt + '') > -1
        })
        this.audit.appendData.field3 = 2
      }
      if (roles.indexOf('全部') > -1) {
        value = this.selections.filter((item) => {
          return ['3', '9', '301'].indexOf(item.spzt + '') > -1
        })
        this.audit.appendData.field3 = 3
      }
      this.audit.status = type
      if (value.length > 0) {
        await this.$refs.form.validate().then(
          (res) => {
            this.loadingStatus(type)
            this.selections.forEach((element) => {
              this.audit.coreKey = element.id;
              this.auditApi(this.audit).then((resp) => {
                if (resp.success) {
                  this.toCloseAudit(true);
                  this.resetField();
                }
              });
            });
          },
          (err) => { }
        );
        this.toQuery()
      } else {
        this.$notify({
          type: "success",
          message: "请选择需要自己审核的数据",
          duration: 2000,
        });
      }
    },


  },
};
</script>
<style lang="scss" scoped>
.ckeckbox-st {
  font-size: 21px;
  top: 50%;
  position: absolute;
  right: 5%;
}

.cancel {
  color: #999999;
  position: absolute;
  top: 20px;
  right: 30px;
}

.select {
  border: 0px #fff solid;
  width: 100%;
  height: 26px;
  background-color: #fff;
  font-size: 15px;
  color: #323233;
  margin-left: -8px;
}
</style>